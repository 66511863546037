import React from "react"
// import { Link } from "gatsby"
import styled from "styled-components"

// import Config from "../config/"
import ContentWrapper from "../styles/ContentWrapper"
// import Logo from "./logo"

// const { footerLinks } = Config

const StyledFooter = styled.footer``

const StyledContentWrapper = styled(ContentWrapper)`
  && {
    width: 100%;
    height: 100%;
    max-width: 25rem;
    padding-bottom: 2.5rem;
    padding-top: 0.25rem;
    .inner {
      margin-top: 1rem;
      border-top: 20px solid ${({ theme }) => theme.colors.secondary};
      padding: 0 1rem;
    }
    .row {
      display: flex;
      justify-content: space-between;
      .col {
        flex-basis: auto;
        &:last-child {
          margin-top: 1.5rem;
        }
      }
    }
    .col a:last-child {
      padding-left: 8px;
    }
    h2 {
      color: black;
    }
    .address {
      font-size: 1.5rem;
      .maila {
        display: inline-block;
        border-bottom: 2px solid;
        &:hover {
          border-color: ${({ theme }) => theme.colors.secondary};
        }
      }
    }
  }
`

// const StyledLink = styled(Link)``

const Footer = () => (
  <StyledFooter id="kontakt">
    <StyledContentWrapper>
      <div className="inner">
        <div className="row">
          <div className="col">
            <h2>Kontakt</h2>
          </div>
          <div className="col">
            <a href="https://www.facebook.com/Picture-this--106644581251926" target="_blank">
              <img src="fb.svg" alt="" />
            </a>
            <a href="https://www.instagram.com/picturethismedia" target="_blank">
              <img src="insta.svg" alt="" />
            </a>
          </div>
        </div>

        <div className="address">
          <p>
            Rafael Neziri <br />
            079 686 95 15
            <br />
            <a href="mailto:rafael.neziri@picturethis.ch" class="maila">
              rafael.neziri@picturethis.ch
            </a>
          </p>
          <p>
            Picture this <br /> Feldstrasse 60 <br />
            8004 Zürich
          </p>
        </div>
      </div>
    </StyledContentWrapper>
  </StyledFooter>
)

export default Footer
