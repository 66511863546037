module.exports = {
  author: "@konstantinmuenster",
  siteTitle: "Picture this — Social Media Development and Campaigning",
  siteShortTitle: "Picture this —", // Used as logo text in header, footer, and splash screen
  siteDescription: "Den Interessen ein Gesicht und eine Geschichte geben.",
  siteUrl: "https://www.picturethis.ch",
  siteLanguage: "de_CH",
  siteIcon: "src/content/favicon.png", // Relative to gatsby-config file

  splashScreen: false, // Set this to true if you want to use the splash screen

  // You can create your own Medium feed with this rss to json converter: https://rss2json.com/
  // To access your Medium RSS feed, just replace this url with your username: https://medium.com/feed/@{yourname}
  mediumRssFeed:
    "https://api.rss2json.com/v1/api.json?rss_url=https%3A%2F%2Fmedium.com%2Ffeed%2F%40konstantin.muenster",
  shownArticles: 3,

  // There are icons available for the following platforms:
  // Medium, GitHub, LinkedIn, XING, Behance
  // socialMedia: [
  //   {
  //     name: "Linkedin",
  //     url: "https://www.linkedin.com/in/konstantin-muenster/",
  //   },
  //   {
  //     name: "Medium",
  //     url: "https://medium.com/@konstantin.muenster",
  //   },
  //   {
  //     name: "Github",
  //     url:
  //       "https://github.com/konstantinmuenster/gatsby-starter-portfolio-minimal",
  //   },
  //   {
  //     name: "Behance",
  //     url: "https://www.behance.net/konstanmnster",
  //   },
  // ],

  navLinks: {
    menu: [
      {
        name: "Den Interessen ein Gesicht und eine Geschichte geben.",
        url: "/#articles",
      },
      {
        name: "About Me",
        url: "/#about",
      },
      {
        name: "Features",
        url: "/#projects",
      },
    ],
    button: {
      name: "Contact",
      url: "/#contact",
    },
  },

  footerLinks: [
    {
      name: "Privacy",
      url: "/privacy",
    },
    {
      name: "Imprint",
      url: "/imprint",
    },
  ],
}
