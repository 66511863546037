import Theme from "../styles/Theme"
import { useEffect, useState } from "react"

// breakpoints.lg needs to be cleaned and parsed since it is a string like '1200px'
export const detectMobileAndTablet = windowWidth =>
  windowWidth < parseInt(Theme.breakpoints.lg.match(/\d+/gi).join(""))

// detect server-side-rendering to fix bugs while gatsby build
export const isSSR = typeof window === "undefined"

// used to parse the publication date of medium articles
export const parseDate = date => {
  const year = date.substring(0, 4)
  const month = date.substring(5, 7)
  const day = date.substring(8, 10)

  switch (month) {
    case "01":
      return day + " January " + year
    case "02":
      return day + " February " + year
    case "03":
      return day + " March " + year
    case "04":
      return day + " April " + year
    case "05":
      return day + " May " + year
    case "06":
      return day + " June " + year
    case "07":
      return day + " July " + year
    case "08":
      return day + " August " + year
    case "09":
      return day + " September " + year
    case "10":
      return day + " October " + year
    case "11":
      return day + " November " + year
    case "12":
      return day + " December " + year
    default:
      return "No publication date"
  }
}

// export function useScrollDir() {
// const [scrollDir, setScrollDir] = useState("down")

// useEffect(() => {
//   const threshold = 0
//   let lastScrollY = window.pageYOffset
//   let ticking = false

//   const updateScrollDir = () => {
//     const scrollY = window.pageYOffset

//     if (Math.abs(scrollY - lastScrollY) < threshold) {
//       ticking = false
//       return
//     }
//     setScrollDir(scrollY > lastScrollY ? "down" : "up")
//     lastScrollY = scrollY > 0 ? scrollY : 0
//     ticking = false
//   }

//   const onScroll = () => {
//     if (!ticking) {
//       window.requestAnimationFrame(updateScrollDir)
//       ticking = true
//     }
//   }

//   window.addEventListener("scroll", onScroll)

//   return () => window.removeEventListener("scroll", onScroll)
// }, [])
// useEffect(() => {
//   return scrollDir
// }, [scrollDir])
// }
export function useScroll() {
  const [lastScrollTop, setLastScrollTop] = useState(0)
  const [bodyOffset, setBodyOffset] = useState(
    typeof window === "undefined" || !window.document
      ? 0
      : document.body.getBoundingClientRect()
  )
  const [scrollY, setScrollY] = useState(bodyOffset.top)
  const [scrollX, setScrollX] = useState(bodyOffset.left)
  const [scrollDirection, setScrollDirection] = useState()

  const listener = e => {
    setBodyOffset(
      typeof window === "undefined" || !window.document
        ? 0
        : document.body.getBoundingClientRect()
    )
    setScrollY(-bodyOffset.top)
    setScrollX(bodyOffset.left)
    setScrollDirection(lastScrollTop > -bodyOffset.top ? "down" : "up")
    setLastScrollTop(-bodyOffset.top)
  }

  useEffect(() => {
    window.addEventListener("scroll", listener)
    return () => {
      window.removeEventListener("scroll", listener)
    }
  })

  return {
    scrollY,
    scrollX,
    scrollDirection,
  }
}
