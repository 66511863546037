import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { Link } from "gatsby"

// import config from "../config/"

// const { navLinks } = config

const StyledBackdrop = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.2);
  z-index: 9;
  transition: all 0.3s ease-in-out;
  display: ${({ open }) => (open ? "block" : "none")};
`

const StyledContainer = styled.div`
  display: block;
  position: fixed;
  z-index: 11;
  top: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100vh;
  outline: 0;
  transition: all 0.3s ease-in-out;
  transform: ${({ open }) => (open ? "translateX(0)" : "translateX(100%)")};
  visibility: ${({ open }) => (open ? "visible" : "hidden")};
`

const StyledNav = styled.nav`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: white;
  height: 100vh;
  width: 480px;
  max-width: 100%;
  text-align: left;
  padding: 2rem;
  position: relative;
  right: 0;
  margin-left: auto;
  a {
    color: ${({ theme }) => theme.colors.primary};
  }
  .nav-link {
    border-top: 8px solid ${({ theme }) => theme.colors.secondary};
    // font-family: ${({ theme }) => theme.fonts.primary};
    font-size: 1.5rem;
    font-weight: normal;
    padding: 1rem 0;
    &.insta, &.fb {
      border-top: 0;
    }
    &.insta {
      padding-left: 6px;
    }
    &:hover span {
      opacity: 0.75;
    }
  }
  .cta-btn {
    width: auto;
    height: auto;
    margin: 1.5rem auto;
    padding: 1rem 1.5rem;
    font-weight: 700;
    font-size: 1.5rem;
    border-radius: ${({ theme }) => theme.borderRadius};
    border: 0.125rem solid ${({ theme }) => theme.colors.primary};
    background: ${({ theme }) => theme.colors.background};
  }
`

const Sidebar = ({ open, setOpen }) => {
  return (
    <>
      <StyledContainer open={open} aria-hidden={!open} tabIndex={open ? 1 : -1}>
        <StyledNav>
          <div className="smenu">
            <a
              target="_blank"
              className="nav-link fb"
              href="https://www.facebook.com/Picture-this--106644581251926"
              onClick={() => setOpen(!open)}
            >
              <img src="fb.svg" alt="" />
            </a>
            <a
              target="_blank"
              className="nav-link insta"
              href="https://www.instagram.com/picturethismedia"
              onClick={() => setOpen(!open)}
            >
              <img src="insta.svg" alt="" />
            </a>
          </div>

          <Link
            className="nav-link"
            key="intro"
            to="/#intro"
            onClick={() => setOpen(!open)}
          >
            <span>
              Den Interessen <br />
              ein Gesicht und <br />
              eine Geschichte <br />
              geben.
            </span>
          </Link>
          <Link
            className="nav-link"
            key="staerke"
            to="/#staerke"
            onClick={() => setOpen(!open)}
          >
            <span>Unsere Stärke</span>
          </Link>
          <Link
            className="nav-link"
            key="kerngebiete"
            to="/#kerngebiete"
            onClick={() => setOpen(!open)}
          >
            <span>Unsere Kerngebiete</span>
          </Link>
          <Link
            className="nav-link"
            key="angebot"
            to="/#angebot"
            onClick={() => setOpen(!open)}
          >
            <span>
              Picture this —<br />
              das Angebot
            </span>
          </Link>
          <Link
            className="nav-link"
            key="about"
            to="/#about"
            onClick={() => setOpen(!open)}
          >
            <span>About</span>
          </Link>
          <Link
            className="nav-link"
            key="kontakt"
            to="/#kontakt"
            onClick={() => setOpen(!open)}
          >
            <span>Kontakt</span>
          </Link>
        </StyledNav>
      </StyledContainer>
      <StyledBackdrop open={open} />
    </>
  )
}

Sidebar.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
}

export default Sidebar
