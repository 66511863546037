module.exports = {
  colors: {
    primary: "#000000",
    secondary: "#e6007e",
    tertiary: "#009fe3",
    text: "#000000",
    background: "transparent",
    headerbg: "#000000",
    headertxt: "#ffffff",
    cntntwrapperbg: "#ffffff",
  },
  breakpoints: {
    xs: "480px",
    sm: "768px",
    md: "992px",
    lg: "1200px",
  },
  fonts: {
    primary: "tex_gyre_herosregular",
    primarybold: "tex_gyre_herosbold",
    secondary: "Surveyor Display A, Surveyor Display B",
  },
  borderRadius: "1rem",
  pageWidth: "25rem",
  // pageWidth: "62.5rem",
  headerHeight: "3.25rem",
  footerHeight: "7.5rem",
}
